import request from '@/utils/request'
// import requestForm from '@/utils/requestForm'

// 获取年级及科目对应得书本列表
/* params = {
	*gradeId, *subjectId
} */
export function apiGetBooksList(params) {
  return request.get('/h5/book/lists', {params})
}

/* params = {
	*bookId
} */
export function apiGetBookTree(params) {
  return request.get('/h5/book/menuTree', {params})
}