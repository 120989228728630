<template>
  <div class="info">
    <h3>文档信息</h3>
    <van-cell-group inset>
      <van-field
        label="文档名称"
        :placeholder="file.fileName ? file.fileName : fileInfo.fileName"
        readonly
      >
        <template #button>
          <van-uploader accept="*" :after-read="afterReadFile">
            <van-button type="primary" size="mini">重新上传</van-button>
          </van-uploader>
        </template>
      </van-field>
    </van-cell-group>

    <h3>学案发布信息</h3>
    <van-form @submit="onSubmit">
      <van-field
        v-model="caseName"
        name="学案名称"
        label="学案名称"
        placeholder="学案名称"
      />

      <!-- <van-field
        v-model="startTime"
        label="开始时间"
        readonly
        @click-input="starShow = true"
      ></van-field>
      <van-field
        v-model="endTime"
        label="结束时间"
        readonly
        @click-input="endShow = true"
      ></van-field> -->

      <van-field
        v-model="gradeName"
        name="年级"
        label="年级"
        placeholder="年级"
        readonly
        @click-input="showGrade = true"
      >
        <template #button>
          <van-button
            size="mini"
            icon="cross"
            type="danger"
            @click="clearGradAndClass"
            >清空</van-button
          >
        </template>
      </van-field>
      <van-field name="指定类型" label="指定类型">
        <template #input>
          <van-radio-group v-model="createTo" direction="horizontal">
            <van-radio :name="1">全年级</van-radio>
            <van-radio :name="2">指定班级</van-radio>
            <van-radio :name="3">指定学生</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        name="班级"
        label="班级"
        v-show="createTo == 2 || createTo == 3"
      >
        <template #input>
          <van-checkbox-group v-model="classIds" direction="horizontal">
            <van-checkbox
              v-for="item in classArr"
              :key="item.id"
              :name="item.id"
              >{{ item.className }}</van-checkbox
            >
          </van-checkbox-group>
        </template>
      </van-field>
      <div class="relStudent" v-show="createTo == 3">
        <van-field label="发布同学">
          <template #input>
            <p v-if="isHaveName" style="margin: 0; color: #d1d1d1">
              请搜索添加学生
            </p>
            <van-tag
              v-for="(item, index) in studentNameArr"
              :key="item.id"
              closeable
              size="medium"
              type="primary"
              @close="closeStudentTag(index)"
            >
              {{ item.name }}
            </van-tag>
          </template>
        </van-field>
        <van-field
          v-model="studentName"
          label="搜索学生"
          placeholder="请选择同学"
          autocomplete="off"
          @update:model-value="changeName"
        >
          <template v-if="showCloseName" #button>
            <van-button size="mini" type="danger" @click="clearAndClose"
              >关闭</van-button
            >
          </template>
        </van-field>
        <ul class="selectItem">
          <li
            v-for="item in studentList"
            :key="item.id"
            @click="
              selectStudent(
                item.studentName + item.gradeName + item.className,
                item.id
              )
            "
          >
            {{ item.studentName }} {{ item.gradeName }} {{ item.className }}
          </li>
        </ul>
      </div>

      <van-field
        v-model="subjectName"
        name="学科"
        label="学科"
        placeholder="学科"
        readonly
        @click-input="showSubject = true"
      />

      <van-field
        v-model="bookName"
        name="教材"
        label="教材"
        placeholder="教材"
        readonly
        @click-input="showBook = true"
      />

      <van-field
        v-model="tree"
        name="目录"
        label="目录"
        placeholder="目录"
        readonly
        @click-input="showTree = true"
      />

      <div style="margin: 16px">
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          size="small"
        >
          {{ isPrint }}
        </van-button>
      </div>
    </van-form>

    <!-- 年级弹出层 -->
    <van-popup
      v-model:show="showGrade"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        title="选择年级"
        :columns="gradeList"
        @confirm="onConfirmGrade"
        @cancel="showGrade = false"
      />
    </van-popup>

    <!-- 学科弹出层 -->
    <van-popup
      v-model:show="showSubject"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        title="选择学科"
        :columns="subjectList"
        @confirm="onConfirmSubject"
        @cancel="showSubject = false"
      />
    </van-popup>

    <!-- 教材弹出层 -->
    <van-popup
      v-model:show="showBook"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        title="选择教材"
        :columns="bookList"
        @confirm="onConfirmBook"
        @cancel="showBook = false"
      />
    </van-popup>

    <!-- 目录 -->
    <van-popup v-model:show="showTree">
      <div class="treeBox">
        <c-tree
          :list="treeList"
          :defaultIds="treeDefaultId"
          @callback="clickChapterTree"
        />
      </div>
    </van-popup>

    <!-- 开始时间 -->
    <!-- <van-popup
      v-model:show="starShow"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        :loading="isLoadingShow"
        title="选择时间"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="starShow = false"
        @confirm="startConfirmFn"
      />
    </van-popup> -->

    <!-- 结束时间 -->
    <!-- <van-popup
      v-model:show="endShow"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        :loading="isLoadingShow"
        title="选择时间"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="endShow = false"
        @confirm="endConfirmFn"
      />
    </van-popup> -->
  </div>
</template>

<script setup>
import { ref, defineProps, onBeforeMount, defineEmits } from "vue";
import { useRouter } from "vue-router";
import cTree from "@/components/ChapterTree";
import { Toast } from "vant";
import { apiGetGradesList } from "@/service/grade.js";
import { apiGetClassesList } from "@/service/class.js";
import { apiStudentList } from "@/service/teacher.js";
import { apiGetSubjectsList } from "@/service/subject.js";
import { apiGetBooksList, apiGetBookTree } from "@/service/book.js";
import { uploadFile } from "@/service/upload.js";
import { apiCreatePrintTask } from "@/service/teacher1.4.js";
import { prefix } from "@/common/js/utils.js";
import { file } from "@babel/types";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const fileInfo = ref(JSON.parse(localStorage.getItem("file")));
let props = defineProps(["file"]);
let emit = defineEmits(["changeActive"]);
const router = useRouter();

let isPrint = ref(+localStorage.getItem("type") == 3 ? "创建学案" : "打印预览");

onBeforeMount(async () => {
  await getGrade();
});

// 组件显示与隐藏
let showGrade = ref(false);
let showSubject = ref(false);
let showBook = ref(false);
let showTree = ref(false);
let starShow = ref(false);
let endShow = ref(false);

// 年级
let gradeList = ref([]);
let gradeName = ref("");
let gradeId = ref(0);
let subjectList = ref([]);
let subjectName = ref("");
let subjectId = ref(0);
const getGrade = async () => {
  let res = await apiGetGradesList();
  if (res) {
    gradeList.value = res.data.map((item) => {
      return {
        text: item.gradeName,
        value: item.id,
      };
    });
  }
};
const onConfirmGrade = async (val) => {
  gradeName.value = val.text;
  gradeId.value = val.value;
  showGrade.value = false;
  await getClassList();
  let res = await apiGetSubjectsList({ gradeId: val.value });
  if (res) {
    subjectList.value = res.data.map((item) => {
      return {
        text: item.subjectName,
        value: item.id,
      };
    });
  }
};

// 学科
const onConfirmSubject = async (val) => {
  subjectName.value = val.text;
  subjectId.value = val.value;
  showSubject.value = false;

  let res = await apiGetBooksList({
    gradeId: gradeId.value,
    subjectId: subjectId.value,
  });
  if (res) {
    bookList.value = res.data.map((item) => {
      return {
        text: item.bookName,
        value: item.id,
      };
    });
  }
};

// 教材
let bookList = ref([]);
let bookName = ref("");
let bookId = ref(0);
const onConfirmBook = async (val) => {
  bookName.value = val.text;
  bookId.value = val.value;
  showBook.value = false;

  let res = await apiGetBookTree({ bookId: bookId.value });
  treeList.value = res.data;
};

// 目录
let treeList = ref([]);
let treeDefaultId = ref([0, "all"]);
let tree = ref("");
let treeId = ref("");
const clickChapterTree = (e) => {
  tree.value = e.title;
  treeId.value = e.id;
};

// 年级清空按钮
const clearGradAndClass = () => {
  gradeName.value = "";
  gradeId.value = "";
  classArr.value = [];
  classIds.value = [];
  studentNameArr.value = [];
  studentIds.value = [];
  // classListIdsArr.value = [];
};

// 发布对象
let createTo = ref(1);

// 班级
let classArr = ref([]);
let classIds = ref([]);
const getClassList = async () => {
  let res = await apiGetClassesList({
    gradeId: gradeId.value,
    academicYear: userInfo.now_academicYear,
  });
  if (res) {
    classArr.value = res.data;
  }
};

// 学生
let isHaveName = ref(true);
let studentNameArr = ref([]);
let NameTimer = null;
let studentName = ref("");
let showCloseName = ref(false);
let studentList = ref([]);
let studentIds = ref([]);
// 搜索学生
const changeName = async () => {
  let pop = document.querySelector(".selectItem");
  pop.style.display = "block";
  showCloseName.value = true;
  if (!studentName.value) {
    pop.style.display = "none";
    showCloseName.value = true;
  }

  // 防抖
  if (NameTimer) {
    clearTimeout(NameTimer);
  }
  NameTimer = setTimeout(async () => {
    let res = await apiStudentList({
      gradeId: gradeId.value,
      classId: classIds.value.join(","),
      searchName: studentName.value,
    });
    if (res) {
      if (res.data.length) {
      } else {
        Toast.fail("没有找到该生");
      }
      studentList.value = res.data;
    }
  }, 500);
};

const clearAndClose = () => {
  let pop = document.querySelector(".selectItem");
  pop.style.display = "none";
  showCloseName.value = false;
};

const closeStudentTag = (index) => {
  studentIds.value.splice(index, 1);
  studentNameArr.value.splice(index, 1);

  if (!studentNameArr.value.length) {
    isHaveName.value = true;
  }
};

const selectStudent = (name, id) => {
  isHaveName.value = false;
  studentNameArr.value.push({ show: true, name: name });
  studentIds.value.push(id);
  studentName.value = "";
};

// // 开始时间
// let startTime = ref("");
// let endTime = ref("");
// let isLoadingShow = ref(false);
// let minDate = new Date();
// let maxDate = new Date(2099, 11, 31, 23, 59, 59);
// let currentDate = new Date(new Date().setHours(new Date().getHours()));

// // 开始时间
// const startConfirmFn = (val) => {
//   starShow.value = false;
//   endShow.value = false;
//   let year = val.getFullYear();
//   let month = val.getMonth() + 1;
//   let day = val.getDate();
//   let hour = val.getHours();
//   let minute = val.getMinutes();
//   if (month >= 1 && month <= 9) {
//     month = "0" + month;
//   }
//   if (day >= 1 && day <= 9) {
//     day = "0" + day;
//   }
//   if (hour >= 0 && hour <= 9) {
//     hour = "0" + hour;
//   }
//   if (minute >= 0 && minute <= 9) {
//     minute = "0" + minute;
//   }
//   startTime.value = year + "-" + month + "-" + day + " " + hour + ":" + minute;
// };
// // 结束时间
// const endConfirmFn = (val) => {
//   starShow.value = false;
//   endShow.value = false;
//   let year = val.getFullYear();
//   let month = val.getMonth() + 1;
//   let day = val.getDate();
//   let hour = val.getHours();
//   let minute = val.getMinutes();
//   if (month >= 1 && month <= 9) {
//     month = "0" + month;
//   }
//   if (day >= 1 && day <= 9) {
//     day = "0" + day;
//   }
//   if (hour >= 0 && hour <= 9) {
//     hour = "0" + hour;
//   }
//   if (minute >= 0 && minute <= 9) {
//     minute = "0" + minute;
//   }
//   endTime.value = year + "-" + month + "-" + day + " " + hour + ":" + minute;
// };

// 重新上传
const afterReadFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file.file);
  let res = await uploadFile(formData);
  if (res) {
    localStorage.setItem(
      "file",
      JSON.stringify({
        fileName: res.data.file.name,
        filePath: res.data.file.url,
        ext: res.data.file.suffix,
      })
    );
    fileInfo.value.fileName = res.data.file.name;
  }
};

let caseName = ref("");
let formData = ref({});
const onSubmit = async (values) => {
  formData.value.title = caseName.value;
  // formData.value.startTime = startTime.value;
  // formData.value.endTime = endTime.value;
  formData.value.gradeId = gradeId.value;
  formData.value.classIds = classIds.value;
  formData.value.studentIds = studentIds.value;
  formData.value.subjectId = subjectId.value;
  formData.value.bookId = bookId.value;
  formData.value.treeId = treeId.value;
  formData.value.createTo = createTo.value;

  if (!caseName.value) {
    Toast.fail("请填写学案名称");
    return;
  }

  if (createTo.value == 1) {
    if (!gradeId.value) {
      Toast.fail("请选择年级");
      return;
    }
  } else if (createTo.value == 2) {
    if (!classIds.value.length) {
      Toast.fail("请选择年级");
      return;
    }
  } else if (createTo.value == 3) {
    if (!studentIds.value.length) {
      Toast.fail("请选择学生");
      return;
    }
  }
  if (!subjectId.value) {
    Toast.fail("请选择学科");
    return;
  }
  if (!bookId.value) {
    Toast.fail("请选择教材");
    return;
  }
  if (!treeId.value) {
    Toast.fail("请选择目录");
    return;
  }

  if (+localStorage.getItem("type") == 3) {
    let file = prefix(fileInfo.value.filePath);
    let data = {
      caseName: caseName.value,
      to:
        createTo.value == 1
          ? "grade"
          : createTo.value == 2
          ? "class"
          : "student",
      subjectId: subjectId.value,
      booksId: bookId.value,
      booksListId: treeId.value,
      gradeId: gradeId.value,
      classIds: classIds.value.join(","),
      studentNos: studentIds.value.join(","),
    };
    let res = await apiCreatePrintTask({
      file: file,
      type: +localStorage.getItem("type"),
      case_info: data,
    });
    if (res) {
      emit("changeActive", 2);
      router.push({ path: "/teacher/printFile/creating" });
      localStorage.setItem("active", 2);
    }
  } else {
    localStorage.setItem("setting", JSON.stringify(formData.value));
    router.push({ path: "/teacher/PrintReview" });
  }
};

// 打印
const printTask = async () => {
  let file = prefix(fileInfo.value.filePath);
  let data = {
    caseName: caseName.value,
    to:
      createTo.value == 1 ? "grade" : createTo.value == 2 ? "class" : "student",
    subjectId: subjectId.value,
    booksId: bookId.value,
    booksListId: treeId.value,
    gradeId: gradeId.value,
    classIds: classIds.value.join(","),
    studentIds: studentIds.value.join(","),
  };
  let res = await apiCreatePrintTask({
    // file: file,
    type: +localStorage.getItem("type"),
    case_info: data,
  });
  if (res) {
    return res.data;
  }
};
</script>

<style lang="less" scoped>
@media screen and (orientation: landscape) {
  :deep(.van-picker) {
    width: 95%;
    margin-left: 45px;
  }
}

:deep(.van-checkbox) {
  margin-bottom: 10px;
}
.relStudent {
  position: relative;
  .selectItem {
    width: 30%;
    max-height: 200px;
    overflow: scroll;
    position: absolute;
    z-index: 1;
    left: 10%;
    // transform: translate(-75%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 15px;
    box-sizing: border-box;
    border-radius: 5px;
    display: none;
    transition: 1s;

    &::after {
      content: "";
      width: 0px;
      height: 0px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0, 0, 0, 0.5);
      position: absolute;
      top: -10px;
      left: 42px;
    }
    li {
      text-align: center;
      line-height: 25px;
      color: #fff;
      font-size: 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

.treeBox {
  width: 50vw;
  height: 50vw;
  position: relative;
  padding: 10px;
}
</style>
