import requestForm from "@/utils/requestForm";

/* params = {
	*file
} */
export function uploadFile(params) {
  return requestForm.post("/h5/ajax/upload", params);
}

/* params = {
	*imgBase, fileName
} */
export function uploadImgBase(params) {
  return requestForm.post("/h5/ajax/uploadImgBase", params);
}
