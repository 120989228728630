<template>
  <div class="chapter-tree">
    <van-collapse v-model="collapesActive" accordion>
      <van-collapse-item title="目录" name="1">
        <van-collapse v-model="activeOne" accordion>
          <div v-for="(oneItem, oneIndex) in treeList" :key="oneIndex">
            <van-collapse-item
              v-if="oneItem.children.length > 0"
              :title="oneItem.title"
              :name="oneItem.chapterId"
              :title-class="oneItem.chapterId == activeOne ? 'treeActive' : ''"
              @click.stop="changeActive(oneItem)"
            >
              <van-collapse v-model="activeTwo" accordion>
                <div
                  v-for="(twoItem, twoIndex) in oneItem.children"
                  :key="twoIndex"
                >
                  <van-collapse-item
                    v-if="twoItem.children.length > 0"
                    :title="twoItem.title"
                    :name="twoItem.chapterId"
                    :title-class="
                      twoItem.chapterId == activeTwo ? 'treeActive' : ''
                    "
                    @click.stop="changeActive(twoItem)"
                  >
                    <van-collapse v-model="activeThree" accordion>
                      <div
                        v-for="(threeItem, threeIndex) in twoItem.children"
                        :key="threeIndex"
                      >
                        <van-collapse-item
                          v-if="threeItem.children.length > 0"
                          :title="threeItem.title"
                          :name="threeItem.chapterId"
                          :title-class="
                            threeItem.chapterId == activeThree
                              ? 'treeActive'
                              : ''
                          "
                          @click.stop="changeActive(threeItem)"
                        >
                        </van-collapse-item>

                        <van-cell
                          class="singleTree"
                          v-else
                          :title="threeItem.title"
                          :title-class="
                            threeItem.chapterId == activeThree
                              ? 'treeActive'
                              : ''
                          "
                          @click.stop="changeActive(threeItem)"
                        />
                      </div>
                    </van-collapse>
                  </van-collapse-item>

                  <van-cell
                    class="singleTree"
                    v-else
                    :title="twoItem.title"
                    :title-class="
                      twoItem.chapterId == activeTwo ? 'treeActive' : ''
                    "
                    @click.stop="changeActive(twoItem)"
                  />
                </div>
              </van-collapse>
            </van-collapse-item>

            <van-cell
              class="singleTree"
              v-else
              :title="oneItem.title"
              :title-class="oneItem.chapterId == activeOne ? 'treeActive' : ''"
              @click.stop="changeActive(oneItem)"
            />
          </div>
        </van-collapse>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  props: {
    active: {
      type: String,
      default: "0",
    },
    defaultIds: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["callback"],
  setup(props, ctx) {
    const state = reactive({
      collapesActive: props.active,
      activeOne:
        typeof props.defaultIds[1] != "undefined" ? props.defaultIds[1] : "",
      activeTwo:
        typeof props.defaultIds[2] != "undefined" ? props.defaultIds[2] : "",
      activeThree:
        typeof props.defaultIds[3] != "undefined" ? props.defaultIds[3] : "",
      treeList: props.list,
    });

    const changeActive = (item) => {
      const chapterArr = item.pathIDs.split(",").filter((s) => {
        return s && s.trim();
      });

      state.activeOne =
        typeof chapterArr[1] != "undefined" ? chapterArr[1] : "";
      state.activeTwo =
        typeof chapterArr[2] != "undefined" ? chapterArr[2] : "";
      state.activeThree =
        typeof chapterArr[3] != "undefined" ? chapterArr[3] : "";

      ctx.emit("callback", item);
    };

    return {
      ...toRefs(state),
      changeActive,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../common/style/base.less";
@import "../common/style/mixin.less";

/* 竖屏 */
@media screen and (orientation: portrait) {
}

/* 横屏 */
@media screen and (orientation: landscape) {
}
</style>
<style>
.treeActive {
  color: #66ccff;
}
.chapter-tree .singleTree {
  padding: 10px;
}
.chapter-tree .van-collapse-item__title {
  font-size: 10px;
  padding: 10px;
}
.chapter-tree .van-collapse-item__content {
  padding: 0 6px;
}
.chapter-tree .van-cell__title {
  font-size: 10px;
}
</style>
