import request from "@/utils/request";
import requestForm from "@/utils/requestForm";

/** 班级列表
 * 参数：
	{
		gradeId: 1, //年级ID，非必传
		academicYear: "2022 - 2023", //学年，非必传
	}
 */
export const apiGetTeacherBindClass = (params) => {
  return request.get("h5/teacher.ClassManage/classList", { params });
};

/**学生列表
 * 参数：
	{
		classId: 10, //必传
		gradeId: 1, //非必传
		academicYear: '2022 - 2023' //非必传
	}
 */
export const apiGetClassManageStudentList = (params) => {
  return request.get("h5/teacher.ClassManage/studentList", { params });
};

/**搜索学生
 *  params = {
 *    gradeId: 1,
			classId: 1,
			searchName: ''
 *  }
 */
export const apiSearchStudent = (params) => {
  return request.get("/h5/common.condition/studentList", { params });
};

/**重置学生密码
 * 参数：
	{
		studentIds: '1,2,3'   //可批量
	}
 */
export const apiResetStudentPassword = (params) => {
  return requestForm.post(
    "h5/teacher.ClassManage/resetStudentPassword",
    params
  );
};

/**新增和编辑学生
 * 参数：
	{
		id: 0, //学生ID，非必传，传为修改，不传为新增
		studentsNo: '', //学生账号
		studentName: '', //学生名称
		sex: 0, //学生性别 0默认 1男 2女
		gradeID: 0, //年级ID
		classID: 0, //班级ID
	}
 */
export const apiSaveStudent = (params) => {
  return requestForm.post("h5/teacher.ClassManage/saveStudent", params);
};

/**学案数据概览
 * start_time 开始时间戳
 * end_time 结束时间
 * class_id 班级id
 * main_id  学案id
 */
export const apiGetOverviewOfAll = (params) => {
  return request.get("/h5/teacher.Analyze/caseAnalyze", { params });
};

/**班级分析图表
 * start_time 开始时间戳, 可以不传
 * end_time 结束时间戳，可以不传
 * class_id 班级id，可以不传
 * main_id 学案id，可以不传
 */
export const apiGetEchartsData = (params) => {
  return request.get("/h5/teacher.Analyze/classChart", { params });
};

/**排行榜
 * start_time 开始时间戳, 可以不传
 * end_time 结束时间戳，可以不传
 * order_by 要排序的方式， 支持dqNum, correctRate, avgTakeTime
 * class_id 班级id，可以不传
 * main_id 学案id，可以不传
 */
export const apiGetStudentRank = (params) => {
  return request.get("/h5/teacher.Analyze/studentRank", { params });
};

/**平均正确率和平均用时人数图表
 * start_time 开始时间戳,可以不传
 * end_time 结束时间戳,可以不传
 * class_id 班级id，可以不传
 * main_id 学案id，可以不传
 * selects
 */
export const apiDistribution = (data) => {
  return requestForm.post("/h5/teacher.Analyze/dynamicDistribution", data);
};

/**
 * case_id
 */
export const apiGetCaseInfo = (params) => {
  return request.get("/h5/teacher/caseInfo", { params });
};

/**学案各类型题目数量
 * main_id 学案id
 */
export const apiQuestionTypeTotal = (params) => {
  return request.get("/h5/teacher.Analyze/questionClassification", { params });
};

/**学案各类型题目统计分析
 * main_id 学案id
 * class_id 班级id
 * question_type 题目类型 1-选择题 2-判断题 3-主观题
 */
export const apiGetCaseStatic = (params) => {
  return request.get("/h5/teacher.Analyze/caseQuestion", { params });
};

/**每道题目各类学生列表
 * type 可选值范围：
		correctPNum - 答对人数
		errorPNum - 答错人数
		allPNum - 作答人数人数, 平均用时
		unDqPNum - 未作答人数
   qa_id 题目id, 取上一个接口返回的qaIds
 */
export const apiGetAnswerStudentList = (params) => {
  return request.get("/h5/teacher.Analyze/answerStudentList", { params });
};

/**题目答案分布
 * qa_id
 */
export const apiGetQuestionAnswer = (params) => {
  return request.get("/h5/teacher.Analyze/questionAnswerDistributed", {
    params,
  });
};

/**学案资源列表
 * main_id  学案id
 * class_id  班级id
 */
export const apiGetCaseSourceList = (params) => {
  return request.get("/h5/teacher.Analyze/caseSourceList", { params });
};

/**学习资源的学生列表
 * source_id  资源id
 */
export const apiGetStudentLearningTime = (params) => {
  return request.get("/h5/teacher.Analyze/learningSourceStudents", { params });
};

/**打印数据概览
 *
 */
export const apiPrintOverview = () => {
  return request.get("/h5/teacher.PrintAnalyze/statistics");
};

/**添加一个打印任务
 * file 文件url
 * type 类型： 1 - 打印并创建学案 2 - 仅打印 3 - 仅创建学案
 * case_info
 * print_setting
 */
export const apiCreatePrintTask = (params) => {
  return requestForm.post("/h5/teacher.Prints/createPrintTask", params);
};

/**文档分析(更多数据) */
export const apiGetPrintStatistics = () => {
  return request.get("/h5/teacher.PrintAnalyze/statistics");
};

/**文档分析（近7日分析） */
export const apiGetSevenChart = () => {
  return request.get("/h5/teacher.PrintAnalyze/chart");
};

/**文档分析（每日数据）
 * page  页码
 * pageSize  每页数量
 */
export const apiGetDayPrintData = (params) => {
  return request.get("/h5/teacher.PrintAnalyze/table", { params });
};

/**文档查询
 * keyword
 */
export const apiSearchFile = (params) => {
  return request.get("/h5/teacher.Prints/search", { params });
};

/**重新打印
 * taskId
 */
export const apiAgainPrint = (params) => {
  return request.get("/h5/teacher.Prints/rePrint", { params });
};

/**查询打印状态
 *  taskId
 */
export const apiTaskStatus = (params) => {
  params.notShowToast = true;
  return request.get("/h5/teacher.Prints/taskStatus", { params });
};
