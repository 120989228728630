import request from '@/utils/request'
// import requestForm from '@/utils/requestForm'

// 获取班级列表
/* params = {
	*gradeId, *academicYear
} */
export function apiGetClassesList(params) {
  return request.get('/h5/classes/lists', {params})
}

//获取学生列表
/* params = {
	*gradeId, *academicYear, *classId
} */
export function apiGetStudentList(params) {
  return request.get('/h5/classes/studentList', {params})
}